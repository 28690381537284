// Dependencies
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';
import NoDataFound from '../../components/NoDataFound';
import SectionHeader from '../../components/SectionHeader';
import Publication from '../../components/Publication';
import PublicationHighlight from '../../components/PublicationHighlight';
import './style.scss';
import ImageContainer from '../../components/ImageContainer';

const apiUrlBase = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:4200';
  
function Research({ language }) {
  const { t, i18n } = useTranslation();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState({ HighlightedPublications: [], Publications: [] });
  const [ publications, setPublications ] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(language === 'ja' ? 'ja' : 'en');
    const fetchData = async () => {
      setIsLoading(true);
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 5000);
      const result = await axios.get(`${apiUrlBase}/cms/research`);
      setData(result.data);
      setPublications(result.data.Publications);
      setIsLoading(false);
      clearTimeout(timeout);
    }
    fetchData();
  }, [i18n, language]);


  function groupPublications(_data) {
    const groupedData = [];
    _data.forEach(item => {
      const timestamp = new Date(item.fields.timestamp);
      if (!timestamp || !timestamp.getFullYear()) return;
      const _year = timestamp.getFullYear();
      if (groupedData.find(item => item.year === _year)) {
        groupedData.find(item => item.year === _year).publications.push( item );
      } else {
        groupedData.push({
          year: _year,
          publications: [ item ]
        });
      }
    });
    return groupedData;
  }

  function handleSearchFieldChange(event) {
    const _publications = data.Publications.filter(item => {
      const _title = item.fields.title.toLowerCase();
      const _authors = item.fields.authors.toLowerCase();
      const _media = item.fields.media.toLowerCase();
      const _searchValue = event.target.value.toLowerCase();
      return _title.includes(_searchValue) || _authors.includes(_searchValue) || _media.includes(_searchValue);
    });
    setPublications(_publications);
  }

  return (
    <div className="page--research">
      <Loader isLoading={isLoading} />

      {/* Research Principles */}
      <div className="page--research__principles__container">
        <div className="page--research__principles__container__backdrop"></div>
        <div className="page--research__principles">
          <div className="grid">
            <div className="col-12">
              <div className="page--research__principles__hero-tagline">
                <h3 className="inverse">{t('RESEARCH__PRINCIPLES__HERO_TAGLINE')}</h3>
              </div>
            </div>
            <div className="col-6 page--research__principles__overview-copy">
              <h6 className="inverse">{t('RESEARCH__PRINCIPLES__OVERVIEW_1')}</h6>
            </div>
            <div className="col-6_sm-12">
              <ImageContainer ratio={'ratio--16-9'} url={require('../../assets/gifs/behavior.gif')} />
              <h6 className="inverse page--research__principles__overview-copy--mobile">{t('RESEARCH__PRINCIPLES__OVERVIEW_1')}</h6>
            </div>
            <div className="col-6_sm-12">
              <ImageContainer ratio={'ratio--16-9'} url={require('../../assets/gifs/comparison.gif')} />
              <h6 className="inverse page--research__principles__overview-copy--mobile">{t('RESEARCH__PRINCIPLES__OVERVIEW_2')}</h6>
            </div>
            <div className="col-6 page--research__principles__overview-copy">
              <h6 className="inverse">{t('RESEARCH__PRINCIPLES__OVERVIEW_2')}</h6>
            </div>
            <div className="col-6 page--research__principles__overview-copy">
              <h6 className="inverse">{t('RESEARCH__PRINCIPLES__OVERVIEW_3')}</h6>
            </div>
            <div className="col-6_sm-12">
              <ImageContainer ratio={'ratio--16-9'} url={require('../../assets/gifs/calcium.gif')} />
              <h6 className="inverse page--research__principles__overview-copy--mobile">{t('RESEARCH__PRINCIPLES__OVERVIEW_3')}</h6>
            </div>
            <div className="col-6_sm-12">
              <ImageContainer ratio={'ratio--16-9'} url={require('../../assets/gifs/complex.gif')} />
              <h6 className="inverse page--research__principles__overview-copy--mobile">{t('RESEARCH__PRINCIPLES__OVERVIEW_4')}</h6>
            </div>
            <div className="col-6 page--research__principles__overview-copy">
              <h6 className="inverse">{t('RESEARCH__PRINCIPLES__OVERVIEW_4')}</h6>
            </div>
          </div>  
        </div>
      </div>
      
      {/* Publications */}
      <div className="page--research__publications">
        <div className="grid">
          <div className="col-12">
            <div className="page--research__publications__header">
              <SectionHeader label={t('RESEARCH__PUBLICATIONS__HEADER')} />
            </div>
          </div>

          {/* Highlighted Publications */}
          {data.HighlightedPublications?.map((item, index) => (
            <div className="col-12" key={`publication-highlight--${index}`}>
              <PublicationHighlight
                title={language === 'ja' && item.fields.title_ja ? item.fields.title_ja : item.fields.title}
                abstruct={language === 'ja' && item.fields.abstruct_ja ? item.fields.abstruct_ja : item.fields.abstruct}
                link={item.fields.link}
                image={item.fields?.image?.[0]?.url}
              />
            </div>
          ))}

          <div className="col-8_md-6_sm-0"></div>

          {/* Search Field */}
          <div className="col-4_md-6_sm-12">
            <div className="page--research__publications__search">
              <div className="page--research__publications__search__icon"></div>
              <input
                className="page--research__publications__search__input-field"
                type="text"
                onChange={(event) => handleSearchFieldChange(event)}
                placeholder={t('RESEARCH__PUBLICATIONS__SEARCH_PLACEHOLDER')}
              />
            </div>
          </div>

          {/* Publication List */}
          <div className="col-12">
            <div className="page--research__publications__list">
              {groupPublications(publications)?.map((group, index) => (
                <div className="page--research__publications__list__group" key={`publication-group--${index}`}>
                  <h6 className="page--research__publications__list__group__year">{group.year}</h6>
                  {group.publications.map((item, index) => (
                    <Publication
                      authors={item.fields.authors}
                      title={item.fields.title}
                      media={item.fields.media}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
          {publications.length === 0 &&
            <div className="col-12">
              <NoDataFound />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Research;
