// Dependencies
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Divider from '../../components/Divider';
import HeroInteractive from '../../components/HeroInteractive';
import ImageContainer from '../../components/ImageContainer';
import Loader from '../../components/Loader';
import News from '../../components/News';
import NewsCarousel from '../../components/NewsCarousel';
import NoDataFound from '../../components/NoDataFound';
import SectionHeader from '../../components/SectionHeader';

import './style.scss';

import { parseTags } from '../../utils';

const apiUrlBase = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:4200';

function Home({ language }) {
  const { t, i18n } = useTranslation();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState({ News: [], Tiles: [] });

  useEffect(() => {
    i18n.changeLanguage(language === 'ja' ? 'ja' : 'en');
    const fetchData = async () => {
      setIsLoading(true);
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 5000);
      const result = await axios.get(`${apiUrlBase}/cms/home`);
      setData(result.data);
      setIsLoading(false);
      clearTimeout(timeout);
    }
    fetchData();
  }, [i18n, language]);

  return (
    <div className="page--home">
      <Loader isLoading={isLoading} />
      <div className="page--home__continer">
        <div className="grid">

          {/* Tagline */}
          <div className="col-6_sm-12">
            <div className="page--home__tagline">
              <h1 className="page--home__tagline__copy">{t('HOME__TAGLINE')}</h1>
            </div>
            <div className="page--home__description">
              <p>{parseTags(t('HOME__DESCRIPTION'))}</p>
            </div>
          </div>

          {/* Hero Image */}
          <div className="col-6_sm-12" id="hero-interactive-canvas__container">
            <div className="page--home__image">
              {/* <div className="page--home__image__background"></div> */}
              <HeroInteractive />
            </div>
          </div>

          {/* Section Divider */}
          <div className="col-12">
            <Divider />
          </div>

          {/* News */}
          <div className="col-12">
            <SectionHeader label={t('HOME__NEWS__HEADER')}/>
          </div>

          {/* News Items - Highlights */}
          <div className="col-12">
            <div className="page--home__news__carousel">
              <NewsCarousel
                data={data.News?.filter(item => item.fields.isActive && item.fields.image)}
                language={language}
              />
            </div>
          </div>

          {data.News?.filter(item => item.fields.isActive && !item.fields.image).map((item, index) => (
            <div className="col-6_sm-12" key={`news--${index}`}>
              <News
                title={language === 'ja' && item.fields.title_ja ? item.fields.title_ja : item.fields.title}
                description={language === 'ja' && item.fields.description_ja ? item.fields.description_ja : item.fields.description}
                link={item.fields.link}
                isHighlight={false}
              />
            </div>
          ))}

          {data.News?.filter(item => item.fields.isActive).length === 0 &&
            <div className="col-12">
              <NoDataFound />
            </div>
          }
          
          {/* Section Divider */}
          <div className="col-12">
            <Divider />
          </div>

          {/* Research Overview Images */}
          <div className="col-6_sm-12">
            <div className="page--home__research-overview__images">
              <div className="page--home__research-overview__image__1"></div>
              <div className="page--home__research-overview__image__2"></div>
            </div>
          </div>

          {/* Research Overview Copies */}
          <div className="col-6_sm-12">
            <h4 className="page--home__research-overview__headline highlight">{t('HOME__RESEARCH_OVERVIEW__HEADER')}</h4>
            <p className="page--home__research-overview__description">{parseTags(t('HOME__RESEARCH_OVERVIEW__DESCRIPTION'))}</p>
            {/* <a href={'https://google.com'}>Read More &gt;</a> */}
          </div>

          {/* Section Divider */}
          <div className="col-12">
            <Divider />
          </div>

          {/* Nine Tiles */}
          {data.Tiles?.filter(item => item.fields.isActive)?.map((item, index) => {
            return (
              <div className="col-4_sm-6" key={`tile--${index}`}>
                <ImageContainer
                  url={item.fields.image[0].url}
                />
              </div>
            )
          })}
          {data.Tiles?.filter(item => item.fields.isActive).length === 0 &&
            <div className="col-12">
              <NoDataFound />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Home;
