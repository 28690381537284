import { useTranslation } from 'react-i18next';

import LinkStack from '../LinkStack';
import './style.scss';

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="component--footer">
        <div className="component--footer__container">
          <div className="grid">
            <div className="col-6_md-12">
              <div className="component--footer__titles">
                <h4 className="inverse component--footer__titles__team">{t('FOOTER__TEAM_NAME')}</h4>
                <h6 className="inverse component--footer__titles__affiliation">{t('FOOTER__AFFILIATION')}</h6>
              </div>
            </div>
            <div className="col-3_md-6_sm-12">
              <LinkStack
                title={t('FOOTER__LINK_TITLE__RESOURCES')}
                links={[
                  { label: '遺伝学電子博物館 -線虫-', url: 'https://www.nig.ac.jp/museum/livingthing11.html' },
                  { label: '九州大学附属図書館 -線虫-', url: 'https://guides.lib.kyushu-u.ac.jp/ModelOrg_Celegans' }
                ]}
              />
            </div>
            <div className="col-3_md-6_sm-12">
              <LinkStack
                title={t('FOOTER__LINK_TITLE__COMMUNITY_html')}
                links={[
                  { label: 'Japanese <i>C. elegans</i> Community (虫の集い)', url: 'http://www.wormjp.umin.jp/jp/index-j.html' },
                  { label: 'nFuture (線虫研究の未来を創る会）', url: 'https://nfuture.jp/' },
                  { label: '24th International <i>C. elegans</i> Conference', url: 'https://genetics-gsa.org/celegans/' },
                  { label: 'Worm meetings', url: 'https://wormmeetings.weebly.com/' },                  
                ]}
              />
            </div>
            <div className="col-12-noBottom">
              <div className="compnent--footer__copyrights">
                <p className="small inverse">© Asuka Takeishi, {new Date().getFullYear()} - all rights reserved.</p>
              </div>
            </div>
          </div>
        
        </div>
    </div>
  );
}

export default Footer;
