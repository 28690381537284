export function parseTags(str) {
  if (!str) return ( <></> );

  const iTag = { start: '<i>', end: '</i>' };

  const tags = [];
  let _str = str;
  while (_str.includes(iTag.start) && _str.includes(iTag.end)) {
    tags.push(_str.substring(0, _str.indexOf(iTag.start)));
    tags.push(<i>{_str.substring(_str.indexOf(iTag.start) + iTag.start.length, _str.indexOf(iTag.end))}</i>);
    _str = _str.substring(_str.indexOf(iTag.end) + iTag.end.length);
  }
  tags.push(_str);

  return (
    <span>
      {tags.map((item, index) => {
        return (item);
      })}
    </span>
  );
}