import React from "react";
import Sketch from "react-p5";

import './style.scss';

const HeroInteractive = () => {
  let t = 0;
  let traction = 0;
  let agility = 0.04;

  const setup = (p5, canvasParentRef) => {
    if (!document.getElementById('hero-interactive-canvas__container')) return;
    if (document.getElementById('hero-interactive-canvas')) return;
    const _w = document.getElementById('hero-interactive-canvas__container').offsetWidth - 32;
    const _size = _w > 500 ? 500 : _w;
    p5.createCanvas(_size, _size).id('hero-interactive-canvas').parent(canvasParentRef);
  };

  const mousePressed = (p5) => {
    agility = 0.16;
  };

  const windowResized = (p5) => {
    if (!document.getElementById('hero-interactive-canvas__container')) return;
    const _w = document.getElementById('hero-interactive-canvas__container').offsetWidth - 32;
    const _size = _w > 500 ? 500 : _w;
    p5.resizeCanvas(_size, _size);
  };

  const draw = (p5) => {
    const _w = p5.width;
    const _h = p5.height;
    const numOfPoints = 20;
    const padding = 40;
    const amplitude = 20;
    traction = 25 * p5.sin(2 * p5.PI * t/20);
    const _dist = p5.dist(p5.mouseX, p5.mouseY, p5.pmouseX, p5.pmouseY);
    agility += _dist * .00005;

    p5.clear();
    p5.background(0, 0, 0, 0);

    p5.noStroke();
    p5.fill(54, 224, 112, 24);
    p5.ellipse(p5.width/2, p5.width/2, p5.width, p5.width);

    p5.noFill();
    p5.stroke(54, 224, 112, 148);
    p5.strokeWeight(3);
    p5.beginShape();
      p5.curveVertex(padding, _h/2 + amplitude * p5.sin(t));
      p5.curveVertex(padding, _h/2 + amplitude * p5.sin(t));
      for (let i=1; i<numOfPoints-1; i++) {
        p5.curveVertex(
          padding + i*((_w - 2*padding) / (numOfPoints-1)),
          _h/2 + amplitude * p5.sin(t + ( 2*p5.PI * ((i/numOfPoints)))) + (i/numOfPoints)*traction
        );
      }
      p5.curveVertex(_w - padding, _h/2 + amplitude * p5.sin(t + ( 2*p5.PI * ((numOfPoints-1) / numOfPoints) )) + ((numOfPoints-1)/numOfPoints)*traction );
      p5.curveVertex(_w - padding, _h/2 + amplitude * p5.sin(t + ( 2*p5.PI * ((numOfPoints-1) / numOfPoints) )) + ((numOfPoints-1)/numOfPoints)*traction );
    p5.endShape();
    t += agility;
    agility *= 0.99;
    if (agility < 0.04) agility = 0.04;
  };

  return (
    <div className="component--hero-interactive">
      <Sketch
        setup={setup}
        draw={draw}
        mousePressed={mousePressed}
        windowResized={windowResized}
      />
    </div>
  );
};

export default HeroInteractive;