import './style.scss';

function SectionHeader({ label, type }) {
  return (
    <div className={`component--section-header ${type ? `component--section-header--${type}` : ''}`}>
      {type !== 'subheader' && <h3>{label}</h3>}
      {type === 'subheader' && <h4>{label}</h4>}
    </div>
  );
}

export default SectionHeader;
