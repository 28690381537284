import './style.scss';
import { parseTags } from '../../utils';

function LinkStack({ title, links }) {
  return (
    <div className="component--link-stack">
      <div className="component--link-stack__title">
        <span>{parseTags(title)}</span>
      </div>
      <div className="component--link-stack__links">
        <ul className="component--link-stack__links__ul">
          {links.map((link, index) => {
            const labelElement = parseTags(link.label);
            return (
              <li className="component--link-stack__links__li" key={`link--${index}`}>
                <a className="inverse" href={link.url}>{labelElement}</a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  );
}

export default LinkStack;
