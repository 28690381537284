import './style.scss';

function OpenReq({ title, link, location }) {
  return (
    <div className="component--open-req">
      <div className="component--open-req__copies">
        <a target="_blank" href={link} rel="noreferrer">
          <h6 className="component--open-req__copies__title">{title}</h6>
        </a>
        <p className="component--open-req__copies__location">{location}</p>
      </div>
    </div>
  );
}

export default OpenReq;
