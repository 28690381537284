import './style.scss';
import { parseTags } from '../../utils';

function Publication({
  authors,
  title,
  media
}) {
  return (
    <div className="component--publication">
      <p>{parseTags(authors)}: "{parseTags(title)}" {parseTags(media)}</p>
    </div>
  );
}

export default Publication;
