import './style.scss';

function Loader({ isLoading }) {
  return (
    <div className={`component--loader ${isLoading ? '' : 'hidden'}`}>
      <div className="component--loader__element"></div>
      <div className="component--loader__element"></div>
      <div className="component--loader__element"></div>
      <div className="component--loader__element"></div>
    </div>
  );
}

export default Loader;
