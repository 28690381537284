import './style.scss';

function Divider() {
  return (
    <div className="component--divider">
      <div className="component--divider__bar"></div>
    </div>
  );
}

export default Divider;
