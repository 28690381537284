import { useTranslation } from 'react-i18next';
import './style.scss';

function NoDataFound() {
  const { t } = useTranslation();

  return (
    <div className="component--no-data-found">
      <p className="component--no-data-found__label">{t('GLOBAL__NO_DATA_FOUND')}</p>
    </div>
  );
}

export default NoDataFound;
