import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import './style.scss';

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  if (isMenuOpen) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }

  return (
    <div className="component--navigation">
      <div className="component--navigation__container" id="app-navigation-container">
        <div className="component--navigation__titles">
          <h1 className="h4 component--navigation__titles__team">{t('NAVIGATION__TEAM_NAME')}</h1>
          <h6 className="component--navigation__titles__affiliation">{t('NAVIGATION__AFFILIATION')}</h6>
        </div>
        <div className="component--navigation__items desktop">
          <a className={`component--navigation__item ${location.pathname === (i18n.language === 'ja' ? '/ja' : '/') ? 'active' : ''}`} href={i18n.language === 'ja' ? '/ja' : '/'}>{t('NAVIGATION__ITEM__HOME')}</a>
          <a className={`component--navigation__item ${location.pathname === (i18n.language === 'ja' ? '/ja/research' : '/research') ? 'active' : ''}`} href={i18n.language === 'ja' ? '/ja/research' : '/research'}>{t('NAVIGATION__ITEM__RESEARCH')}</a>
          <a className={`component--navigation__item ${location.pathname === (i18n.language === 'ja' ? '/ja/our-lab' : '/our-lab') ? 'active' : ''}`} href={i18n.language === 'ja' ? '/ja/our-lab' : '/our-lab'}>{t('NAVIGATION__ITEM__OUR_LAB')}</a>
          <a className={`component--navigation__item`} href={i18n.language === 'ja' ? '/' : '/ja'}>{t('NAVIGATION__ITEM__LANGUAGE')}</a>
        </div>
        <div className="component--navigation__items mobile">
          <div className={`component--navigation__three-bars ${isMenuOpen ? 'open' : ''}`} onClick={() => { setIsMenuOpen(!isMenuOpen); window.scrollTo(0, 0); }}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div className={`component--navigation__mobile-menu ${isMenuOpen ? 'open' : ''}`}>
            <a className={`component--navigation__item ${location.pathname === (i18n.language === 'ja' ? '/ja' : '/') ? 'active' : ''}`} href={i18n.language === 'ja' ? '/ja' : '/'}>{t('NAVIGATION__ITEM__HOME')}</a>
            <a className={`component--navigation__item ${location.pathname === (i18n.language === 'ja' ? '/ja/research' : '/research') ? 'active' : ''}`} href={i18n.language === 'ja' ? '/ja/research' : '/research'}>{t('NAVIGATION__ITEM__RESEARCH')}</a>
            <a className={`component--navigation__item ${location.pathname === (i18n.language === 'ja' ? '/ja/our-lab' : '/our-lab') ? 'active' : ''}`} href={i18n.language === 'ja' ? '/ja/our-lab' : '/our-lab'}>{t('NAVIGATION__ITEM__OUR_LAB')}</a>
            <a className={`component--navigation__item`} href={i18n.language === 'ja' ? '/' : '/ja'}>{t('NAVIGATION__ITEM__LANGUAGE')}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
