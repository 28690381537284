import './style.scss';
import { parseTags } from '../../utils';

function Profile({ name, position, description, portrait, linkToCv }) {
  console.log(linkToCv, 'linkToCv');
  return (
    <div className="component--profile">
      <div className="component--profile__image" style={{ backgroundImage: `url(${portrait})` }}></div>
      <div className="component--profile__copies">
        <h4 className="component--profile__copies__name">{parseTags(name)}</h4>
        <p className="component--profile__copies__position">{parseTags(position)}</p>
        <p className="component--profile__copies__description">{parseTags(description)}</p>
        {linkToCv && <a className="component--profile__copies__link-to-cv" href={linkToCv} download>Open CV</a>}
      </div>
    </div>
  );
}

export default Profile;
