const en = {
  translation: {
    // Navigation
    NAVIGATION__TEAM_NAME : 'Neural Circuit of Multisensory Integration',
    NAVIGATION__AFFILIATION: 'RIKEN Hakubi Research Team',
    NAVIGATION__ITEM__HOME: 'Home',
    NAVIGATION__ITEM__RESEARCH: 'Research',
    NAVIGATION__ITEM__OUR_LAB: 'Our Lab',
    NAVIGATION__ITEM__LANGUAGE: '日本語',

    // Home
    HOME__TAGLINE: 'We explore\nthe common way\nanimals\nmake decisions',
    HOME__DESCRIPTION: 'How do animals make behavioral choices based on information collected from the environment? We are interested in the way the nervous system integrates multiple environmental cues in order to produce behavior. We use the worm <i>C. elegans</i> as model to investigate mechanisms of integration that are evolutionally-conserved from worms to other animals.',
    HOME__NEWS__HEADER: 'News',
    HOME__RESEARCH_OVERVIEW__HEADER: 'To Understand\nWho We Are',
    HOME__RESEARCH_OVERVIEW__DESCRIPTION: 'We are interested in how animals make decisions. All animals, from humans to worms, collect information from the environment. To make use of that information, the nervous system must go through a process called integration. Integration is how the animal combines information. It can refer to combining new information with other new information (ex. the sight and smell of a new place) or new information with old information (ex. how a place looks now and how it looked two weeks ago). Integrating many sources of information over their entire lifespans allows animals to make the best decisions they can in order to survive. We use a type of worm called <i>C. elegans</i> as an animal model. Model animals are easy to study and provide results that can be applied to different animals without having to study them directly. We use these worms to investigate how exactly the nervous system can combine information gathered from different senses. More technically, we are looking for the mechanism of multi-sensory integration. We analyze how the worms behave when given different environmental information. We also look directly at the nervous system by analyzing functional groups of neurons called circuits. In addition to the previously mentioned biological approaches, we incorporate mathematical and computational models to investigate evolutionally-conserved mechanisms of behavioral decision.',

    // Research
    RESEARCH__PRINCIPLES__HERO_TAGLINE: 'Complex Behavior in a Simple System',
    RESEARCH__PRINCIPLES__OVERVIEW_1: 'Similar to other animals, worms will move toward their favorite stimuli such as attractive odors and cultivated temperature.',
    RESEARCH__PRINCIPLES__OVERVIEW_2: 'The behavior and cellular functions of worms are regulated by signaling pathways, most of which are conserved throughout evolution.',
    RESEARCH__PRINCIPLES__OVERVIEW_3: 'To identify the neurons responsible for behavior, we can visualize neural activity in intact animals, even under free-moving conditions.',
    RESEARCH__PRINCIPLES__OVERVIEW_4: 'In addition to responses to single stimuli, worms integrate internal state and/or multiple stimuli, and exhibit complex behavior.',    
    RESEARCH__PUBLICATIONS__HEADER: 'Publications',
    RESEARCH__PUBLICATIONS__SEARCH_PLACEHOLDER: 'Input keywords to search publications',

    // Our Lab
    OUR_LAB__HERO_TAGLINE: 'We Study Worms on Our Own Terms',
    OUR_LAB__ALUMNI: 'Alumni',
    OUR_LAB__JOIN_US__HEADER: 'Join Our Lab',
    OUR_LAB__JOIN_US__DESCRIPTION: 'We are happy to host researchers and students in SPDR and JRA programs of RIKEN. The available positions are listed on RIKEN HP and JrecIn.',
    OUR_LAB__JOIN_US__NO_OPEN_REQ: 'We currently don\'t have an open position.',
    OUR_LAB__CONTACT__HEADER: 'Contact Us',
    OUR_LAB__CONTACT__DESCRIPTION: 'Please contact us in advance if you would like to vist us.',
    OUR_LAB__CONTACT__PHONE: '+81-50-3495-0157',
    OUR_LAB__CONTACT__EMAIL: 'asuka.takeishi [at] riken.jp',
    OUR_LAB__CONTACT__DIRECTION: 'We locate at the room 315 of Ikenohata Building(C56) by a pond. All the visitors need to apply for the entrance permission at the West Gate of Wako Campus',
    OUR_LAB__CONTACT__ADDRESS: '2-1 Hirosawa, Ikenohata Building 315-317, Wako, Saitama, 351-0198, Japan',
    OUR_LAB__CONTACT__ADDRESS_DESCRIPTION: ' ',

    // Footer
    FOOTER__TEAM_NAME : 'Neural Circuit of Multisensory Integration',
    FOOTER__AFFILIATION: 'RIKEN Hakubi Research Team',
    FOOTER__LINK_TITLE__RESOURCES: 'Resources',
    FOOTER__LINK_TITLE__COMMUNITY_html: '<i>C. elegans</i> Research Community',

    // Global
    GLOBAL__NO_DATA_FOUND: 'No data found for this section. There is a chance that we could not connect to the server, so please try reloading your browser window.'
  }
};

export default en;
