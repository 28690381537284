import './style.scss';
import { parseTags } from '../../utils';

function News({
  title,
  description,
  link,
  image,
  degree,
  isHighlight = false,
}) {
  return (
    <div className="component--news">

      {/* Highligt News */}
      {isHighlight &&
        <div className="component--news__highlight">
          <div
            className="component--news__highlight__image"
            style={{
              backgroundImage: `url(${image})`,
              filter: `grayscale(100%) sepia(35%) hue-rotate(${degree}deg)`
            }}
          ></div>
          <div className="component--news__highlight__copies">
            <h6 className="component--news__title">{parseTags(title)}</h6>
            <p className="component--news__description">{parseTags(description)}</p>
            {link && <div className="component--news__link">
              <a target={'_blank'} href={link} rel="noreferrer">Read More</a>
            </div>}
          </div>
        </div>
      }

      {/* Normal News */}
      {!isHighlight &&
        <div className="component--news__normal">
          <h6 className="component--news__title">{parseTags(title)}</h6>
          <p className="component--news__description">{parseTags(description)}</p>
          {link && <div className="component--news__link">
            <a target={'_blank'} href={link} rel="noreferrer">Read More</a>
          </div>}
        </div>
      }
    </div>
  );
}

export default News;
