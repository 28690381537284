const jp = {
  translation: {
    // Navigation
    NAVIGATION__TEAM_NAME : '多感覚統合神経回路',
    NAVIGATION__AFFILIATION: '理研白眉研究チーム',
    NAVIGATION__ITEM__HOME: 'ホーム',
    NAVIGATION__ITEM__RESEARCH: '研究内容',
    NAVIGATION__ITEM__OUR_LAB: '研究室',
    NAVIGATION__ITEM__LANGUAGE: 'English',

    // Home
    HOME__TAGLINE: '行動を\n生み出す\n脳の仕組みを\n理解する',
    HOME__DESCRIPTION: '日々刻々と変化する周辺環境に応じて、生き物はどのように適切な行動を選択するのでしょうか？最適な行動を選択するためには、環境中のさまざまな刺激の情報を脳で統合する必要があります。私たちはC. エレガンスと呼ばれる線虫を研究に用いて、線虫からヒトにまで進化的に保存された、普遍的な情報統合の仕組みを明らかにすることを目指しています。',
    HOME__NEWS__HEADER: 'ニュース',
    HOME__RESEARCH_OVERVIEW__HEADER: '私たちが目指す研究',
    HOME__RESEARCH_OVERVIEW__DESCRIPTION: ' 線虫やヒトを含む全ての生き物は、自分の周りの環境について様々な情報を感じ取り、それらの情報を脳や神経系に集めて、どの行動を取るのが一番良いかを判断します。統合される情報には、その時々に感じる情報（視覚や匂いなど）のみならず、過去の記憶や経験、生き物の体調の情報なども含まれます。統合した情報に基づいて行動を決定することにより、生き物は生存の可能性を高めたり、生活の質を良くすることができます。脳の疾患などで情報の統合が適切にできない場合には、行動障害が起きることがわかっており、情報統合の仕組みの理解は社会的にも重要な課題です。\n 脳で情報を統合する仕組みを明らかにするために、私たちはC. エレガンスと呼ばれる線虫をモデル動物として実験に使っています。モデル動物とは、線虫やハエ、魚、ネズミなど、比較的簡単に実験を行うことができる動物です。モデル動物の実験結果をもとに、他の生き物に共通した仕組みを調べることにより、ヒトなど実験が難しい生き物の体の仕組みを知ることができます。\n 私たちは特に、「脳が異なる感覚の情報をどのように統合するのか」について研究をしています（多感覚統合の仕組み）。匂いや温度などの環境を変えると、線虫の行動がどのように変化するのかを測定したり、行動が変わる時にどの神経細胞が応答するのかを調べています。このような生物学の実験に加えて、数学やシミュレーションを取り入れることにより、多くの生き物に共通した行動決定の仕組みを明らかにすること目指しています。',

    // Research
    RESEARCH__PRINCIPLES__HERO_TAGLINE: 'シンプルな生き物で複雑な行動の仕組みを理解する',
    RESEARCH__PRINCIPLES__OVERVIEW_1: '他の生き物と同様、線虫は好きな匂いや温度など、好む刺激の方に寄っていきます。',
    RESEARCH__PRINCIPLES__OVERVIEW_2: '姿や形は違いますが、線虫の体の仕組みや行動、機能の多くはヒトと共通のたんぱく質により制御されています。',
    RESEARCH__PRINCIPLES__OVERVIEW_3: '自由に動く線虫で神経活動を測定することにより、行動を制御する神経細胞を調べることができます。',
    RESEARCH__PRINCIPLES__OVERVIEW_4: '一つの刺激だけでなく、体の調子や複数の刺激の情報を統合することで、複雑な行動を生み出します。',    
    RESEARCH__PUBLICATIONS__HEADER: '発表論文',
    RESEARCH__PUBLICATIONS__SEARCH_PLACEHOLDER: '検索キーワード',

    // Our Lab
    OUR_LAB__HERO_TAGLINE: '線虫をみながら、日々、一喜一憂',
    OUR_LAB__ALUMNI: '元ラボメンバー',
    OUR_LAB__JOIN_US__HEADER: 'メンバー募集',
    OUR_LAB__JOIN_US__DESCRIPTION: '理化学研究所のSPDR、JRAプログラムの研究者や大学院生を受け入れています。\n公募情報は理化学研究所の採用ページまたはJrecInをご参照ください。',
    OUR_LAB__JOIN_US__NO_OPEN_REQ: '現在募集中のポジションはありません。',
    OUR_LAB__CONTACT__HEADER: '連絡先',
    OUR_LAB__CONTACT__DESCRIPTION: 'メールの[at]を@にご変更ください。',
    OUR_LAB__CONTACT__PHONE: '050-3495-0157',
    OUR_LAB__CONTACT__EMAIL: 'asuka.takeishi [at] riken.jp',
    OUR_LAB__CONTACT__DIRECTION: '研究室は和光市駅から徒歩15分の理化学研究所和光キャンパス内にあります。\nお越しの際は、西門にて、入構手続きをお願いいたします。',
    OUR_LAB__CONTACT__ADDRESS: '〒351-0198 埼玉県和光市広沢2-1 CBS池の端棟315室',
    OUR_LAB__CONTACT__ADDRESS_DESCRIPTION: '',

    // Footer
    FOOTER__TEAM_NAME : '多感覚統合神経回路',
    FOOTER__AFFILIATION: '理研白眉研究チーム',
    FOOTER__LINK_TITLE__RESOURCES: 'Resources',
    FOOTER__LINK_TITLE__COMMUNITY: '<i>C. elegans</i> 研究コミュニティ',

    // Global
    GLOBAL__NO_DATA_FOUND: 'このセクションに該当するデータが見つかりませんでした。サーバーにアクセスにアクセス出来なかった可能性があるので、ブラウザを再読み込みしてみて下さい。'
  }
};

export default jp;
