import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Components
import Navigation from './components/Navigation';
import Footer from './components/Footer';

// Pages
import Home from './pages/Home';
import Research from './pages/Research';
import OurLab from './pages/OurLab';

import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route index element={<Home />}></Route>
        <Route path="research" element={<Research />}></Route>
        <Route path="our-lab" element={<OurLab />}></Route>
        <Route path="/ja" element={<Home language={'ja'} />}></Route>
        <Route path="/ja/research" element={<Research language={'ja'} />}></Route>
        <Route path="/ja/our-lab" element={<OurLab language={'ja'} />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
