import { useEffect, useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import News from '../News';
import './style.scss';

function NewsCarousel({ data, language }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }
    window.addEventListener('resize', handleResize)
  });

  return (
    <div className="component--news-carousel">
      <Swiper
        modules={[ Navigation, Pagination ]}
        spaceBetween={isMobile ? 12 : 24}
        slidesPerView={isMobile ? 1 : 2}
        loop={false}
        navigation
        pagination={{ clickable: true }}
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide key={`news-highlight--${index}`}>
              <News
                isHighlight={true}
                title={language === 'ja' && item.fields.title_ja ? item.fields.title_ja : item.fields.title}
                image={item.fields.image[0].url}
                description={language === 'ja' && item.fields.description_ja ? item.fields.description_ja : item.fields.description}
                link={item.fields.link}
                degree={(index / data.length) * 720}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default NewsCarousel;
