import './style.scss';
import { parseTags } from '../../utils';

function PublicationHighlight({
  title,
  abstruct,
  link,
  image
}) {
  return (
    <div className="component--publication-highlight">
      <div
        className="component--publication-highlight__image"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="component--publication-highlight__copies">
        <h6 className="component--publication-highlight__copies__title">{parseTags(title)}</h6>
        <p className="component--publication-highlight__copies__abstruct">{parseTags(abstruct)}</p>
        {link && <div className="component--publication-highlight__link">
          <a target={'_blank'} href={link} rel="noreferrer">Read More</a>
        </div>}
      </div>
    </div>
  );
}

export default PublicationHighlight;
